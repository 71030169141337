import { gql } from '@apollo/client';

import {
  SALE_TRANSFER_INFO,
  CLP_ACCOUNT,
  MXN_ACCOUNT,
  USER_INFO,
  CATEGORY_BENEFIT_INFO,
} from './fragments';

export const ME = gql`
  query me {
    me {
      id
      email
      username
      firstName
      fullName
      secretCode
    }
  }
`;

export const USD_TO_CURRENCY = gql`
  query usdToCurrency {
    usdToCurrency
  }
`;

export const LEADERS_HEAR_ABOUT_US_CHART = gql`
  query leadersHearAboutUsChart {
    leadersHearAboutUsChart
  }
`;

export const ADMIN_USERS = gql`
  query adminUsers(
    $offset: Int
    $limit: Int
    $search: String
    $role: UserRoleEnum
    $superAdmin: Boolean
    $withErrors: Boolean
    $saleProblems: Boolean
  ) {
    adminUsers(
      offset: $offset
      limit: $limit
      search: $search
      role: $role
      superAdmin: $superAdmin
      withErrors: $withErrors
      saleProblems: $saleProblems
    ) {
      ...userInfo
    }
  }
  ${USER_INFO}
`;

export const ADMIN_USER_LENGTH = gql`
  query adminUsersLength(
    $search: String
    $role: UserRoleEnum
    $superAdmin: Boolean
    $withErrors: Boolean
    $saleProblems: Boolean
  ) {
    adminUsersLength(
      search: $search
      role: $role
      superAdmin: $superAdmin
      withErrors: $withErrors
      saleProblems: $saleProblems
    )
  }
`;

export const ADMIN_LEADERS = gql`
  query adminLeaders($withPlans: Boolean) {
    adminLeaders(withPlans: $withPlans) {
      id
      fullName
      username
      currencyId
      allPlans {
        id
        name
        state
        oneMonthPrice
      }
    }
  }
`;

export const SETTINGS = gql`
query settings {
  settings {
    id
    key
    description
    value
    valueType
  }
}
`;

export const ADMIN_PLANS = gql`
  query allPlans(
    $offset: Int
    $limit: Int
    $search: String
  ) {
    allPlans(
    offset: $offset
    limit: $limit
    search: $search
    ) {
        id
        name
        description
        expiredRenewableMembersCount
        activeMembersCount
        activeMembersForRenovationCount
        oneMonthPrice
        usdPrice
        durationInWords
        isPack
        months
        enableBookingsLimit
        bookingsLimit
        enableTrialDays
        trialDays
        enableStartsAt
        startsAt
        secret
        state
        user {
          id
          username
          currencyId
        }
        enableMultipay
        multipayMax
    }
  }
`;

export const ADMIN_GET_USER_MEMBER_LIST = gql`
  query adminGetUserMemberList(
    $userId: ID!
    $search: String
    $type: UserSubscriptionTypeEnum
    $offset: Int
    $limit: Int
    $filterByMonth: ISO8601DateTime
  ) {
    adminGetUserMemberList(
      userId: $userId
      search: $search
      type: $type
      offset: $offset
      limit: $limit
      filterByMonth: $filterByMonth
    ) {
      id
      user {
        id
        email
        fullName
        phoneNumber
        instagram
        avatar {
          id
          tiny
        }
      }
      plan {
        id
        name
        oneMonthPrice
      }
      hasTrialPlan
      trialPlanDays
      renewal
      expiresAt
    }
  }
`;

export const REVENUE_LEADER = gql`
  query revenueLeader(
    $role: RevenueLeaderRoleEnum!
    $search: String
    $offset: Int
    $limit: Int
    $filterByMonth: ISO8601DateTime
  ) {
    revenueLeaders(
      role: $role
      search: $search
      offset: $offset
      limit: $limit
      filterByMonth: $filterByMonth
    ) {
      id
      fullName
      email
      role
      username
      superAdmin
      shareholder
      featured
    }
  }
`;

export const ADMIN_GET_USER = gql`
  query adminGetUser($id: ID!) {
    adminGetUser(id: $id) {
      ...userInfo
      timezone
      referral
      lastSignInAt
      answers {
        id
        values
        question {
          id
          question
        }
      }
      avatarFeatured {
        id
        tiny
        originalLarge
      }
      controlIntegrations {
        id
        platform
        data
      }
      hasLeaderSiiIntegration
      clpAccount {
        ...clpAccount
      }
      mxnAccount {
        ...mxnAccount
      }
      creditCardsWithDeleted {
        id
        countryId
        credit
        creditCardType
        currencyId
        last4CardDigits
        state
        createdAt
        deletedAt
      }
      discounts {
        id
        code
        usageLimit
        percent
        freeTrialsDays
        freeTrialsMonths
        available
        availableForControl
        usage
        url
        leader {
          id
          fullName
        }
      }
    }
  }
  ${CLP_ACCOUNT}
  ${MXN_ACCOUNT}
  ${USER_INFO}
`;

export const ADMIN_GET_USER_SALES = gql`
  query adminGetUserSales(
    $userId: ID!
    $search: String
    $filterByMonth: ISO8601DateTime
    $offset: Int
    $limit: Int
    $state: String
  ) {
    adminGetUserSales(
      userId: $userId
      search: $search
      filterByMonth: $filterByMonth
      offset: $offset
      limit: $limit
      state: $state
    ) {
      id
      activity {
        id
        name
      }
      plan {
        id
        name
      }
      payedAt
      createdAt
      amount
      currencyId
      creditCardType
      discountCode
      isRenovated
      isPack
      isGift
      state
      invoice
      transactionId
      invoiceToken
      invoicePlatform
      invoiceStatus
      invoicePdf {
        id
        pdf
        pdfUrl
      }
      user {
        id
        email
      }
      crewUser {
        id
        username
        hasDteIntegration
        hasOpenfacturaIntegration
        hasSiiIntegration
        hasBsaleIntegration
      }
      userSubscription {
        id
      }
      paymentErrors
    }
  }
`;

export const ADMIN_GET_USER_BOOKINGS = gql`
  query adminGetUserBookings(
    $id: ID!
    $search: String
    $filterByTime: BookingTimeEnum
    $offset: Int
    $limit: Int
  ) {
    adminGetUserBookings(
      id: $id
      search: $search
      filterByTime: $filterByTime
      offset: $offset
      limit: $limit
    ) {
      id
      startsAt
      endsAt
      activity {
        id
        name
      }
    }
  }
`;

export const ADMIN_GET_USER_RSVPS = gql`
  query adminGetUserRSVPS(
    $id: ID!
    $filterByTime: BookingTimeEnum
    $offset: Int
    $limit: Int
  ) {
    adminGetUser(id: $id) {
      id
      myRsvps(filterByTime: $filterByTime, offset: $offset, limit: $limit) {
        id
        checkedAt
        activity {
          id
          name
          user {
            id
            fullName
          }
        }
        booking {
          id
          startsAt
          endsAt
        }
      }
    }
  }
`;

export const ADMIN_GET_USER_MY_SUBSCRIPTIONS = gql`
  query adminGetMySubscriptions($userId: ID!, $limit: Int, $offset: Int) {
    adminGetUserMySubscriptions(
      userId: $userId
      limit: $limit
      offset: $offset
    ) {
      id
      currencyId
      price
      type
      payedPrice
      canRenew
      expired
      plan {
        id
        name
        oneMonthPrice
      }
      isPack
      collaborationPlan {
        id
      }
      isUpdateable
      crewUser {
        id
        username
      }
      sale {
        id
        last4
      }
      extraClasses
      createdAt
      expiresAt
      renewal
      renewalPlan
      error
      free
      unsubscribeReason
      discountCode
      paypalDetails
      paypalTransactions
    }
  }
`;

export const ADMIN_GET_USER_DISCOUNTS = gql`
  query adminGetUserDiscounts($userId: ID!, $limit: Int, $offset: Int) {
    adminGetUserDiscounts(userId: $userId, limit: $limit, offset: $offset) {
      id
      code
      usageLimit
      percent
      freeTrialsDays
      freeTrialsMonths
      enableUserLimit
      userLimit
      available
      availableForControl
      usage
      url
      leader {
        id
        fullName
      }
    }
  }
`;

export const USER_PROFILE = gql`
  query userProfile($username: String, $id: ID!) {
    userProfile(username: $username, id: $id) {
      id
      firstName
      lastName
      fullName
      email
      phoneNumber
      timezone
      countryId
      role
      username
      superAdmin
      shareholder
      featured
      countryId
      referral
      typeOfAccount
      canSendReminderBeforeActivity
      clpAccount {
        ...clpAccount
      }
      mxnAccount {
        ...mxnAccount
      }
      creditCardsWithDeleted {
        id
        countryId
        credit
        creditCardType
        currencyId
        last4CardDigits
        state
        createdAt
        deletedAt
      }
      sales {
        id
        activity {
          id
          name
        }
        plan {
          id
          name
        }
        payedAt
        amount
        currencyId
        transferredAt
        transactionId
        state
        last4
      }
      discounts {
        id
        code
        usageLimit
        percent
        freeTrialsDays
        freeTrialsMonths
        available
        availableForControl
        usage
        url
        leader {
          id
          fullName
        }
      }
    }
  }
  ${CLP_ACCOUNT}
  ${MXN_ACCOUNT}
`;

export const USER_PLANS = gql`
  query userProfilePlans($id: ID, $secret: String) {
    userProfile(id: $id) {
      id
      currencyId
      allPlans(secret: $secret) {
        id
        name
        description
        expiredRenewableMembersCount
        activeMembersCount
        activeMembersForRenovationCount
        oneMonthPrice
        usdPrice
        durationInWords
        isPack
        months
        enableBookingsLimit
        bookingsLimit
        enableTrialDays
        trialDays
        enableStartsAt
        startsAt
        secret
        url
        state
        user {
          currencyId
        }
        enableMultipay
        multipayMax
      }
    }
  }
`;

export const USER_PROFILE_PLANS = gql`
  query userProfile($username: String, $id: ID!) {
    userProfile(username: $username, id: $id) {
      id
      username
      currencyId
      plans {
        id
        name
      }
    }
  }
`;

export const GET_CLP_ACCOUNT = gql`
  query clpAccount($id: ID!) {
    clpAccount(id: $id) {
      ...clpAccount
    }
  }
  ${CLP_ACCOUNT}
`;

export const GET_MXN_ACCOUNT = gql`
  query mxnAccount($id: ID!) {
    mxnAccount(id: $id) {
      ...mxnAccount
    }
  }
  ${MXN_ACCOUNT}
`;

export const ACTIVITIES = gql`
  query allActivities(
    $search: String
    $state: ActivityStateEnum
    $type: ActivityTypeEnum
    $category: String
    $offset: Int
    $limit: Int
  ) {
    allActivities(
      search: $search
      state: $state
      type: $type
      category: $category
      offset: $offset
      limit: $limit
    ) {
      id
      name
      recurrent
      user {
        id
        email
        username
      }
      category {
        id
        name
      }
      flyer {
        small
      }
      startsAt
      state
      _type
      mediaType
      publishRecording
    }
  }
`;

export const ACTIVITY = gql`
  query activity($id: ID!) {
    activity(id: $id) {
      id
      name
      description
      mediaType
      recurrent
      category {
        name
      }
      sales {
        id
        payedAt
        transferredAt
        transactionId
        state
        amount
        currencyId
        user {
          id
          email
        }
      }
      user {
        id
        email
      }
      category {
        name
        slug
      }
      flyer {
        small
      }
      duration
      startsAt
      createdAt
      spotPrice
      state
      _type
    }
  }
`;

export const GET_BOOKINGS_OF_ACTIVITY = gql`
  query activity($id: ID!, $time: BookingTimeEnum, $offset: Int, $limit: Int) {
    activity(id: $id) {
      id
      name
      bookings(time: $time, offset: $offset, limit: $limit) {
        id
        startsAt
        endsAt
        activity {
          id
          name
        }
      }
    }
  }
`;

export const BOOKINGS = gql`
  query bookings(
    $time: BookingTimeEnum
    $search: String
    $offset: Int
    $limit: Int
  ) {
    bookings(time: $time, search: $search, offset: $offset, limit: $limit) {
      id
      startsAt
      endsAt
      activity {
        id
        name
        user {
          id
          username
        }
      }
      rsvpsCount
      mediaType
    }
  }
`;

export const GET_BOOKING = gql`
  query getBooking($id: ID!) {
    booking(id: $id) {
      id
      startsAt
      endsAt
      activity {
        id
        name
        user {
          id
          username
        }
      }
      rsvpsCheckedCount
      rsvpsCount
      rsvps {
        id
        checkedAt
        user {
          id
          fullName
        }
      }
    }
  }
`;

export const ADMIN_STATS = gql`
  query adminStats {
    adminStats {
      id
      activitiesCount
      activitiesPublishedCount
      activitiesPublishedPercentage
      activitiesDraftedCount
      activitiesDraftedPercentage
      activitiesPublishedAtLastWeekCount
      activitiesPublishedThisWeekCount
      activitiesPublishedThisMonthCount
      activitiesPublishedAtLastMonthCount
      salesCount
      thisDaySales
      thisMonthSales
      nextMonthsPlans
      usersCount
      growthRateOfPublishedActivitiesThisWeek
      growthRateOfCreatedLeadersThisWeek
      growthRateOfSaleThisWeek
      leadersCount
      leadersTrialCount
      leadersInPlanCount
      currentMonthPlans
    }
  }
`;

export const RSVPS_LAST_YEAR = gql`
  query adminStats {
    adminStats {
      id
      rsvpsLastYear
    }
  }
`;

export const GROSS_SALES_LAST_YEAR = gql`
  query adminStats {
    adminStats {
      id
      grossSalesLastYear
    }
  }
`;

export const ADMIN_SALE_STATS = gql`
  query adminSaleStats($filterByMonth: ISO8601DateTime) {
    adminStats {
      salesCount(filterByMonth: $filterByMonth)
      paidSalesCount(filterByMonth: $filterByMonth)
      declinedSalesCount(filterByMonth: $filterByMonth)
      refundedSalesCount(filterByMonth: $filterByMonth)
    }
  }
`;

export const SALES = gql`
  query adminSales(
    $search: String
    $limit: Int
    $offset: Int
    $state: String
    $filterByMonth: ISO8601DateTime
  ) {
    adminSales(
      search: $search
      limit: $limit
      offset: $offset
      state: $state
      filterByMonth: $filterByMonth
    ) {
      id
      activity {
        id
        name
      }
      plan {
        id
        name
      }
      payedAt
      amount
      discountCode
      currencyId
      creditCardType
      isRenovated
      state
      invoice
      invoiceToken
      invoiceStatus
      invoicePlatform
      availableToGenerateDte
      invoicePdf {
        id
        pdf
        pdfUrl
      }
      user {
        id
        email
      }
      crewUser {
        id
        username
        hasDteIntegration
        hasOpenfacturaIntegration
        hasSiiIntegration
        hasBsaleIntegration
      }
      paymentErrors
    }
  }
`;

export const GET_SALE = gql`
  query getSale($id: ID!) {
    getSale(id: $id) {
      id
      activity {
        id
        name
      }
      crewUser {
        id
        fullName
      }
      invoice
      invoiceStatus
      invoiceToken
      invoicePdf {
        id
        pdf
        pdfUrl
      }
      amount
      discountCode
      fee
      gatewayFee
      flycrewPlanName
      flycrewPlanFee
      authCode
      createdAt
      creditCardType
      currencyId
      gateway
      last4
      isRenovated
      secret
      payedAt
      checkoutExpiresAt
      plan {
        id
        name
      }
      state
      tbkStatus
      refundedAt
      saleTransferId
      transactionId
      transferredAt
      user {
        id
        email
        fullName
        hasOpenfacturaIntegration
        hasDteIntegration
        hasSiiIntegration
      }
      paymentErrors
      extraParams
      params
      subscriptionId
    }
  }
`;

export const CATEGORIES = gql`
  query categories {
    categories(all: true) {
      id
      name
      slug
      deletedAt
    }
  }
`;

export const CATEGORY_PASSES = gql`
  query categoriesPasses {
    categoryPasses {
      id
      name
      slug
    }
  }
`;

export const GET_CATEGORY = gql`
  query category($id: ID!) {
    category(id: $id) {
      id
      name
      slug
      deletedAt
    }
  }
`;

export const GET_CATEGORY_PASS = gql`
  query categoryPass($id: ID!) {
    categoryPass(id: $id) {
      id
      name
      slug
    }
  }
`;

export const PENDING_SALES_PER_CURRENCY = gql`
  query adminTransfersPerCurrency($filterByMonth: ISO8601DateTime, $currencyId: String) {
    adminTransfers(filterByMonth: $filterByMonth, currencyId: $currencyId) {
      pendingPerCurrency
    }
  }
`;

export const PENDING_SALES = gql`
  query adminTransfers($filterByMonth: ISO8601DateTime, $currencyId: String) {
    adminTransfers(filterByMonth: $filterByMonth, currencyId: $currencyId) {
      pendingUsers {
        id
        username
      }
    }
  }
`;

export const PENDING_SALES_PLANS = gql`
  query adminTransfersPlans($filterByMonth: ISO8601DateTime, $currencyId: String, $id: ID!) {
    adminTransfers(filterByMonth: $filterByMonth, currencyId: $currencyId) {
      pendingUser(id: $id) {
        id
        fullName
        username
        individualBookings
        totalFee
        totalAmount
        totalPending
        clpAccountValid
        mxnAccountValid
        usdAccountValid
        countryId
        plans {
          name
          id
          fee
          amount
          pending
          available
        }
      }
    }
  }
`;

export const TRANSFERS_SUMMARY = gql`
  query adminTransfers($year: Int!, $month: Int!, $currencyId: String) {
    adminTransfers {
      grossSalesSummary(year: $year, month: $month, currencyId: $currencyId)
      completedSaleTransfers(
        year: $year
        month: $month
        currencyId: $currencyId
      ) {
        ...saleTransferInfo
      }
    }
  }
  ${SALE_TRANSFER_INFO}
`;

export const TRANSFERS = gql`
  query adminTransfers {
    adminTransfers {
      pendingSaleTransfers {
        ...saleTransferInfo
      }
    }
  }
  ${SALE_TRANSFER_INFO}
`;

export const PAYSHEETS = gql`
  query allPaysheets {
    adminTransfers {
      allSalePaysheets {
        id
        name
        saleTransfersCount
        currencyId
        total
        state
        createdAt
        updatedAt
      }
    }
  }
`;

export const PAYSHEET = gql`
  query Paysheet($id: ID!) {
    adminTransfers {
      salePaysheet(id: $id) {
        id
        name
        saleTransfersCount
        saleTransfers {
          ...saleTransferInfo
        }
        total
        state
        createdAt
        updatedAt
        currencyId
        url
        reportUrl
      }
    }
  }
  ${SALE_TRANSFER_INFO}
`;

export const DISCOUNTS = gql`
  query discounts(
    $offset: Int
    $limit: Int
    $search: String
    $state: DiscountEnum
  ) {
    discounts(offset: $offset, limit: $limit, search: $search, state: $state) {
      id
      code
      createdAt
      usageLimit
      percent
      freeTrialsDays
      freeTrialsMonths
      enableUserLimit
      userLimit
      available
      availableForControl
      usage
      url
      leader {
        id
        fullName
      }
    }
  }
`;

export const DISCOUNT = gql`
  query discount($code: String, $id: ID) {
    adminDiscount(code: $code, id: $id) {
      id
      createdAt
      expiresAt
      code
      state
      usage
      usageLimit
      enableUserLimit
      userLimit
      percent
      freeTrialsDays
      freeTrialsMonths
      available
      availableForControl
      url
      leader {
        id
        fullName
      }
      usageBy {
        id
        fullName
      }
      usageByDetails {
        user {
          id
          fullName
        }
        usage
      }
      plans {
        id
        name
        state
      }
      isGift
      amounts
    }
  }
`;

export const COMPANIES = gql`
  query companies {
    companies {
      id
      name
      slug
    }
  }
`;

export const COMPANY = gql`
  query company($id: ID!) {
    company(id: $id) {
      id
      name
      slug
    }
  }
`;

export const TIME_ZONES = gql`
  query timezones {
    timezones
  }
`;

export const BENEFITS = gql`
  query benefits {
    benefits {
      id
      name
      instructions
      linkUrl
      className
      operator
      startsAt
      updatedAt
    }
  }
`;

export const BENEFIT = gql`
  query benefit($id: ID!) {
    benefit(id: $id) {
      id
      name
      instructions
      linkUrl
      startsAt
      operator
      categoryBenefit {
        ...categoryBenefitInfo
      }
      photo {
        id
        tiny
        originalLarge
        originalTiny
      }
    }
  }
  ${CATEGORY_BENEFIT_INFO}
`;

export const CATEGORY_BENEFITS = gql`
  query CategoryBenefits {
    categoryBenefits {
      ...categoryBenefitInfo
    }
  }
  ${CATEGORY_BENEFIT_INFO}
`;

export const CATEGORY_BENEFIT = gql`
  query CategoryBenefit($id: ID!) {
    categoryBenefit(id: $id) {
      ...categoryBenefitInfo
    }
  }
  ${CATEGORY_BENEFIT_INFO}
`;
